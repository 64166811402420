<template>
    <SidebarGroup name="Tax">
        <template #activator>Custom Reports</template>

        <template #icon-left>
            <VIcon decorative name="custom-reports-thick" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import { Feature } from '@/enums/Feature';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import useBaseGroup from '../hooks/useBaseGroup';

export default {
    name: 'ClientSidebarGroupCustomReports',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
        reportMode: PropTypes.string.isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);

        const isDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        return {
            createItems,
            isDexterityScenesEnabled,
            isInsightSupported,
        };
    },

    computed: {
        items() {
            const customReportsRoute = this.isDexterityScenesEnabled ? 'client.reports.unified' : 'client.reports';
            const sandboxRoute = this.isDexterityScenesEnabled ? 'client.sandbox.unified' : 'client.sandbox';

            return this.createItems([
                {
                    label: 'Custom Reports',
                    name: 'Client Reports',
                    to: { name: customReportsRoute, params: { clientSlug: this.client.slug } },
                },
                {
                    label: 'Report Creator',
                    name: 'Client Sandbox',
                    to: { name: sandboxRoute, params: { clientSlug: this.client.slug } },
                },
            ]);
        },
    },
};
</script>

<template>
    <SidebarGroup name="Practice Analysis">
        <template #activator>Practice Dashboards</template>

        <template #icon-left>
            <VIcon decorative name="dashboard-thick" />
        </template>

        <SidebarItem
            name="Practice Dashboard"
            sub-item
            :to="!isDexterityScenesEnabled ? { name: 'team.dashboard' } : { name: 'team.practice-dashboards.practice' }"
        >
            Practice Dashboard
        </SidebarItem>

        <SidebarItem
            v-if="deadlinesEnabled"
            name="Deadlines Dashboard"
            sub-item
            :to="
                !isDexterityScenesEnabled ? { name: 'team.deadlines' } : { name: 'team.practice-dashboards.deadlines' }
            "
        >
            Deadlines Dashboard
        </SidebarItem>

        <SidebarItem
            v-if="isPracticeInsightsDashboardEnabled"
            name="Insights Dashboard"
            sub-item
            :to="{ name: 'team.practiceInsightsDashboard' }"
        >
            Insights Dashboard
        </SidebarItem>

        <SidebarItem
            name="Activity Stats"
            sub-item
            :to="
                !isDexterityScenesEnabled
                    ? { name: 'team.activityStats' }
                    : { name: 'team.practice-dashboards.activity-stats' }
            "
        >
            Activity Stats
        </SidebarItem>

        <SidebarItem
            v-if="linkedToHMRC"
            name="MTD Dashboard"
            sub-item
            :to="!isDexterityScenesEnabled ? { name: 'team.mtdDashboard' } : { name: 'team.practice-dashboards.mtd' }"
        >
            MTD Dashboard
        </SidebarItem>

        <SidebarItem
            name="Focus Dashboard"
            sub-item
            :to="
                !isDexterityScenesEnabled
                    ? { name: 'team.focus.dashboard' }
                    : { name: 'team.practice-dashboards.workflows' }
            "
        >
            Workflows Dashboard
        </SidebarItem>

        <SidebarItem
            name="Custom Reports"
            sub-item
            :to="
                !isDexterityScenesEnabled
                    ? { name: 'team.reports' }
                    : { name: 'team.practice-dashboards.custom-dashboards' }
            "
        >
            Custom Dashboards
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

export default {
    name: 'TeamSidebarGroupPracticeAnalysis',

    components: { SidebarGroup, SidebarItem },

    setup() {
        const { linkedToHMRC, teamLocation } = useXavierGlobals();
        const deadlinesEnabled = useIsFeatureEnabled(Feature.DEADLINES_DASHBOARD) && teamLocation === 'GB';
        const isPracticeInsightsDashboardEnabled = useIsFeatureEnabled(Feature.PRACTICE_INSIGHTS_DASHBOARD);
        const isDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        return {
            deadlinesEnabled,
            isDexterityScenesEnabled,
            isPracticeInsightsDashboardEnabled,
            linkedToHMRC,
        };
    },
};
</script>

<style lang="scss" scoped></style>

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import dashboardsMessages from './messages/en-GB/dashboards.json';
import insightsMessages from './messages/en-GB/insights.json';
import workflowsMessages from './messages/en-GB/workflows.json';

Vue.use(VueI18n);

let i18n: VueI18n;
const defaultLocale = 'en-GB';
const loadedLanguages = [defaultLocale];

const messages = { ...workflowsMessages, ...dashboardsMessages, ...insightsMessages };

/**
 * This is the i18n instance that will be used throughout the application.
 *
 * @link https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
 * @link https://kazupon.github.io/vue-i18n/guide/formatting.html
 */

function setup() {
    i18n = new VueI18n({
        fallbackLocale: defaultLocale,
        locale: defaultLocale,
        messages: { 'en-GB': messages },
    });
}

function setLocale(lang: string) {
    if (i18n.locale !== lang) {
        i18n.locale = lang;
        document.querySelector('html')?.setAttribute('lang', lang);
    }

    return lang;
}

const translationsInstance = {
    loadedLanguages,
    setLocale,
    setup,
    get vueI18n() {
        return i18n;
    },
};

export { defaultLocale, translationsInstance as i18n };

<template>
    <Sidebar>
        <template #prepend>
            <SidebarItem
                class="fs-unmask"
                :disabled="!currentClient"
                exact
                name="Back to Team Home"
                :to="{ name: 'team.home' }"
            >
                <template #icon-left>
                    <VIcon decorative name="chevron-left-thick" :vertical-align="false" />
                </template>
                Back to Team Home
            </SidebarItem>
            <ClientSwitcher :clients="clients" :current-client="currentClient" />
            <SidebarSyncButton v-if="currentClient && currentClient.isIntegrated" :client="currentClient" />
        </template>

        <template v-if="currentClient">
            <SidebarItem exact name="Client Overview" :to="{ name: 'client.overview', params: { clientSlug } }">
                <template #icon-left>
                    <VIcon decorative name="overview-globe-thick" />
                </template>
                Overview
            </SidebarItem>

            <SidebarItem
                name="Client Focus"
                :to="{
                    name: hasDexterityScenesEnabled ? 'client.workflows.list' : 'client.focus',
                    params: { clientSlug },
                }"
            >
                <template #icon-left="{ isActive }">
                    <VIcon decorative :name="isActive ? 'workflows-filled' : 'workflows-thick'" />
                </template>
                Client Workflows
            </SidebarItem>

            <template v-if="currentClient.hasHealthScore">
                <CleanupGroup :client="currentClient" :report-mode="reportMode" />
                <InsightsGroup :client="currentClient" :report-mode="reportMode" />
                <TaxGroup :client="currentClient" :report-mode="reportMode" />
                <CustomReportsGroup :client="currentClient" :report-mode="reportMode" />
                <HistoricalActivityGroup :client="currentClient" :report-mode="reportMode" />
            </template>

            <SettingsGroup :client="currentClient" />
        </template>

        <template v-else>
            {{
                /*
                 * Don't remove this, the app will break for some reason.
                 * Literally spent two hours of my life trying to figure out
                 * that I need the v-else otherwise the v-if above
                 * doesn't work 🤷‍♂️
                 */
            }}
        </template>

        <template v-if="isNovaUser" #append>
            <PrecisionAdminSidebarItems />
        </template>
    </Sidebar>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed, defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import { useTeamContext } from '@/App/composables/useTeamContext';
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import CustomReportsGroup from '@/layout/ClientLayout/ClientSidebar/Groups/CustomReportsGroup.vue';
import HistoricalActivityGroup from '@/layout/ClientLayout/ClientSidebar/Groups/HistoricalActivityGroup.vue';
import TaxGroup from '@/layout/ClientLayout/ClientSidebar/Groups/TaxGroup.vue';
import { PrecisionAdminSidebarItems } from '@/layout/PrecisionAdmin';
import { Sidebar, SidebarItem } from '@/layout/Sidebar';
import ClientService from '@/services/Api/ClientService';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import type { Client } from '@/store/modules/client/types/Client';

import ClientSwitcher from './ClientSwitcher.vue';
import CleanupGroup from './Groups/CleanupGroup.vue';
import InsightsGroup from './Groups/InsightsGroup.vue';
import SettingsGroup from './Groups/SettingsGroup.vue';
import SidebarSyncButton from './SyncButton.vue';

const { useGetters: useClientGetters } = createNamespacedHelpers('legacyClients');

export default defineComponent({
    name: 'ClientSidebar',

    components: {
        CleanupGroup,
        ClientSwitcher,
        CustomReportsGroup,
        HistoricalActivityGroup,
        InsightsGroup,
        PrecisionAdminSidebarItems,
        SettingsGroup,
        Sidebar,
        SidebarItem,
        SidebarSyncButton,
        TaxGroup,
    },

    setup() {
        const { isNovaUser = false } = useXavierGlobals();
        const { currentClient, reportMode, clientList } = useClientGetters([
            'currentClient',
            'reportMode',
            'clientList',
        ]);
        const { currentTeam } = useTeamContext('ClientSidebar');

        const clients = ref<Client[]>([]);
        const clientSlug = computed(() => currentClient.value?.slug ?? null);

        const hasDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        const loadClientList = async () => {
            const { data } = await ClientService.getClientList(currentTeam.value.rbExternalId);

            clients.value = data;
        };

        onBeforeMount(() => {
            if (clientList.value.length === 0) {
                loadClientList();
            } else {
                clients.value = clientList.value;
            }
        });

        return {
            clientSlug,
            clients,
            currentClient,
            hasDexterityScenesEnabled,
            isNovaUser,
            reportMode,
        };
    },
});
</script>

<template>
    <SidebarGroup name="Tax">
        <template #activator>Historical Activity</template>

        <template #icon-left>
            <VIcon decorative name="history-thick" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import useBaseGroup from '../hooks/useBaseGroup';

export default {
    name: 'ClientSidebarGroupHistoricalActivity',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
        reportMode: PropTypes.string.isRequired,
    },

    setup(props) {
        const hasDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);
        const isDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        return {
            createItems,
            hasDexterityScenesEnabled,
            isInsightSupported,
            isDexterityScenesEnabled,
        };
    },

    computed: {
        items() {
            return this.createItems([
                this.isInsightSupported('lock-dates') && {
                    name: 'Lock Date History',
                    label: 'Lock Date History',
                    to: !this.isDexterityScenesEnabled
                        ? { name: 'client.insight.lockDates' }
                        : { name: 'client.insight.lockDates.dextified' },
                },
                this.client.provider === ClientProvider.XERO &&
                    this.isInsightSupported('historical-changes') && {
                        name: 'Historical Changes',
                        label: 'Historical Changes',
                        to: {
                            name: this.hasDexterityScenesEnabled
                                ? 'client.insight.historicalChanges.dextified'
                                : 'client.insight.historicalChanges',
                        },
                    },
            ]);
        },
    },
};
</script>

<template>
    <SidebarGroup name="Tax">
        <template #activator>Tax</template>

        <template #icon-left>
            <VIcon decorative name="tax-categories-filled" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { defineComponent } from 'vue';
import * as PropTypes from 'vue-types';

import countryCodes from '@/enums/CountryCodes';
import { Feature } from '@/enums/Feature';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import useBaseGroup from '../hooks/useBaseGroup';

export default defineComponent({
    name: 'ClientSidebarGroupTax',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object().isRequired,
        reportMode: PropTypes.string().isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);
        const isDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        const commonItems = [
            isInsightSupported('corporation-tax') && {
                label: props.client.corpTaxName,
                name: 'Corporation Tax',
                to: !isDexterityScenesEnabled
                    ? { name: 'client.insight.corporationTax' }
                    : { name: 'client.insight.corporationTax.unified' },
            },
        ];

        return {
            commonItems,
            createItems,
            isInsightSupported,
            isDexterityScenesEnabled,
        };
    },

    computed: {
        abnValidationEnabled() {
            const abnCountryCodes = [countryCodes.AUSTRALIA];
            const clientSupportsAbn = abnCountryCodes.includes(this.client.countryCode);

            return clientSupportsAbn && useIsFeatureEnabled(Feature.ABN_VALIDATION);
        },
        isVatReturnEnabled() {
            return this.validCountryCodes.includes(this.client.countryCode) && this.isInsightSupported('vat-return');
        },
        items() {
            let items = [];

            switch (this.client.countryCode) {
                case countryCodes.GREAT_BRITAIN:
                    items = this.getRegionUkItems();
                    break;
                case countryCodes.AUSTRALIA:
                    items = this.getRegionAuItems();
                    break;
                case countryCodes.CANADA:
                    items = this.getRegionCaItems();
                    break;
                default:
                    items = this.getRegionOtherItems();
            }

            return this.createItems(items);
        },
        validCountryCodes() {
            return this.client.provider === 'xero'
                ? [countryCodes.AUSTRALIA, countryCodes.GREAT_BRITAIN]
                : [countryCodes.GREAT_BRITAIN];
        },
    },
    methods: {
        getRegionAuItems() {
            return [
                this.isVatReturnEnabled && {
                    label: `${this.client.salesTaxName} Return Checks`,
                    name: 'VAT Return Checks',
                    to: { name: 'client.check.vatReturn' },
                },
                this.abnValidationEnabled &&
                    this.isInsightSupported('abn-validation') && {
                        label: 'ABN Validation',
                        name: 'ABN Validation',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.cleanup.abnValidation' }
                            : { name: 'client.cleanup.abnValidation.dextified' },
                    },
            ];
        },
        getRegionCaItems() {
            return [...this.commonItems];
        },
        getRegionOtherItems() {
            return [];
        },
        getRegionUkItems() {
            return [
                this.isVatReturnEnabled && {
                    label: `${this.client.salesTaxName} Return Checks`,
                    name: 'VAT Return Checks',
                    to: { name: 'client.check.vatReturn' },
                },
                ...this.commonItems,
                {
                    label: 'Entertainment Accounts',
                    name: 'Entertainment Accounts',
                    to: { name: 'client.check.entertainmentAccounts' },
                },
            ];
        },
    },
});
</script>

import { extractClientIdParam } from '@/router/utils';

import type { RouteConfig } from 'vue-router';

const taxRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/CorporationTax/CorporationTaxScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insight.corporationTax.unified',
        path: 'tax/corporation-tax',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/VATReturn'),
        name: 'client.tax.vatReturn',
        path: 'tax/vat-return',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/EntertainmentAccountsCheck').then((res) => res.EntertainmentAccountsCheck),
        name: 'client.tax.entertainmentAccounts',
        path: 'tax/entertainment-accounts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/AbnValidation/AbnValidationScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.cleanup.abnValidation.dextified',
        path: 'tax/abn-validation',
        props: extractClientIdParam,
    },
];

export { taxRoutes };

<template>
    <SidebarGroup name="Client Cleanup">
        <template #activator>Cleanup</template>

        <template #icon-left="{ isActive }">
            <VIcon decorative :name="isActive ? 'clean-up-filled' : 'clean-up-thick'" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { defineComponent } from 'vue';
import * as PropTypes from 'vue-types';

import { ClientProvider } from '@/enums/ClientProvider';
import countryCodes from '@/enums/CountryCodes';
import { Feature } from '@/enums/Feature';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import useBaseGroup from '../hooks/useBaseGroup';

export default defineComponent({
    name: 'ClientSidebarGroupCleanup',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object().isRequired,
        reportMode: PropTypes.string().isRequired,
    },

    setup(props) {
        const abnCountryCodes = [countryCodes.AUSTRALIA];
        const clientSupportsAbn = abnCountryCodes.includes(props.client.countryCode);
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);
        const isDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

        const duplicateTransactionsEnabled = useIsFeatureEnabled(Feature.DUPLICATE_TRANSACTIONS);

        const transactionCleanupEnabled =
            props.client.provider === ClientProvider.QUICKBOOKS &&
            useIsFeatureEnabled(Feature.TRANSACTION_CLEANUP) &&
            useIsFeatureEnabled(Feature.TRANSACTION_CLEANUP_FRONTEND);

        const abnValidationEnabled = clientSupportsAbn && useIsFeatureEnabled(Feature.ABN_VALIDATION);

        return {
            abnValidationEnabled,
            createItems,
            duplicateTransactionsEnabled,
            isDexterityScenesEnabled,
            isInsightSupported,
            transactionCleanupEnabled,
        };
    },

    computed: {
        items() {
            return this.createItems([
                this.client.provider === 'xero' &&
                    this.isInsightSupported('sales-analysis') && {
                        label: 'Bank Reconciliation',
                        name: 'Bank Reconciliation',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.cleanup.bankReconciliation' }
                            : { name: 'client.cleanup.bankReconciliation.unified' },
                    },
                this.client.provider === 'quickbooks' &&
                    this.isInsightSupported('unreconciled-transactions') && {
                        label: 'Unreconciled Transactions',
                        name: 'Unreconciled Transactions',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.cleanup.unreconciledTransactions' }
                            : { name: 'client.cleanup.unreconciledTransactions.unified' },
                    },
                this.isInsightSupported('duplicate-contacts') && {
                    label: 'Duplicate Contacts',
                    name: 'Duplicate Contacts',
                    to: !this.isDexterityScenesEnabled
                        ? { name: 'client.cleanup.duplicateContacts.legacy' }
                        : { name: 'client.cleanup.duplicateContacts' },
                },
                this.isInsightSupported('multi-coded') && {
                    label: 'Multi-Coded Contacts',
                    name: 'Multi-Coded Contacts',
                    to: this.isDexterityScenesEnabled
                        ? { name: 'client.cleanup.multi-codedContacts.dextified' }
                        : { name: 'client.cleanup.multi-codedContacts' },
                },
                this.isInsightSupported('contact-defaults') && {
                    label: 'Contact Defaults',
                    name: 'Contact Defaults',
                    to: !this.isDexterityScenesEnabled
                        ? { name: 'client.cleanup.contactDefaults' }
                        : { name: 'client.cleanup.contactDefaults.dextified' },
                },

                this.isInsightSupported('dormant-accounts') && {
                    label: 'Dormant Accounts',
                    name: 'Dormant Accounts',
                    to: !this.isDexterityScenesEnabled
                        ? { name: 'client.cleanup.dormantAccounts' }
                        : { name: 'client.cleanup.dormantAccounts.dextified' },
                },
                this.duplicateTransactionsEnabled &&
                    this.isInsightSupported('duplicate-transactions') && {
                        label: 'Duplicate Transactions',
                        name: 'Duplicate Transactions',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.cleanup.duplicateTransactions' }
                            : { name: 'client.cleanup.duplicateTransactions.unified' },
                    },
                this.transactionCleanupEnabled &&
                    this.isInsightSupported('transaction-cleanup') && {
                        label: 'Transaction Cleanup',
                        name: 'Transaction Cleanup',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.cleanup.transactionCleanup' }
                            : { name: 'client.cleanup.transactionCleanup.dextified' },
                    },
                this.isInsightSupported('fixed-assets') && {
                    label: 'Fixed Assets',
                    name: 'Fixed Assets',
                    to: !this.isDexterityScenesEnabled
                        ? { name: 'client.insight.fixedAssets' }
                        : { name: 'client.insight.fixedAssets.unified' },
                },
                this.isInsightSupported('control-accounts') && {
                    label: 'Control Accounts',
                    name: 'Control Accounts',
                    to: this.isDexterityScenesEnabled
                        ? { name: 'client.insight.controlAccounts.dextified' }
                        : { name: 'client.insight.controlAccounts' },
                },
                this.client.countryCode === countryCodes.AUSTRALIA &&
                    this.client.provider === ClientProvider.XERO &&
                    this.client.payrollSupported && {
                        label: 'Payroll',
                        name: 'Payroll',
                        to: !this.isDexterityScenesEnabled
                            ? { name: 'client.insight.payroll' }
                            : { name: 'client.insight.payroll.unified' },
                    },
            ]);
        },
    },
});
</script>
